export type Resource = {
  resource_type: string
  resource: string
  displayable: boolean
}

export type LinkCategory = {
  id?: number
  type: string
  account_identity: string
  display_order_number: number
  displayable: boolean
}

export type CreatorData = {
  account_identity: string
  icon: string
  name: string
  profile?: Profile
  content_blocks?: ContentItemInterface[]
  is_public: boolean
  filled_profile: boolean
  birthday_confirmed: boolean
  uid: string
  ogp_image: string | null
  user_badge?: UserBadge
}

export type UserBadge = {
  id: number
  ranking_fan_badge_id: number
  creator_id: number
  creator: {
    name: string
    account_identity: string
    icon: string
    uid: string
    is_public: boolean
  }
  user_id: number
  badge: {
    id: number
    ranking_event: {
      id: number
      event_identity: string
      name: string
      image_url: string
    }
    rank: number
    badge_url: string
    badge_top_url: string | null
    image_url: string
  }
}

export type CreatorPopUpData = {
  enable: boolean
  url: string
  button_text: string | undefined
  title: string | undefined
  image: string
  updated_at: string
}

export type Profile = {
  bio: string
  cover: Cover
  header_image: string
  sns_links: LinkCategory[]
  sns_link_color: string
  official_flg: boolean
  theme_color: string
}

export type Cover = {
  brightness: string
  resource: Resource
  cover_visibility: boolean
}

export type Covers = {
  brightness: string
  resources: Resource[]
  cover_visibility: boolean
}

export type ItemContent = {
  id?: number
  image: any
  title: string | undefined
  description: string | undefined
  url: string | undefined
  style?: any
}

export type ContentItemInterface = {
  id: number
  displayable: boolean
  type: string
  count: number
  content: ItemContent[]
  display_order_number?: number
  content_block_type?: ContentBlockType
  content_block_details?: ContentBlockDetails[]
}

export type ContentBlockType = {
  name: string
}

export type ContentBlockDetails = {
  id: number
  title: string
  description: string
  app_description: string
  image: string
  url: string
  content_group_number?: number
  icon: string
  is_setted_icon?: boolean
  style?: any
}

export type BlogArticleData = {
  id: string
  title: string
  description: string
  image_url: string
  label: number
  priority: number
  content: string
}

// THEMES could be one of the following value from light1 to dark16
export enum THEMES {
  light1 = 'light1',
  light2 = 'light2',
  light3 = 'light3',
  light4 = 'light4',
  light5 = 'light5',
  light6 = 'light6',
  light7 = 'light7',
  light8 = 'light8',
  light9 = 'light9',
  light10 = 'light10',
  light11 = 'light11',
  light12 = 'light12',
  light13 = 'light13',
  light14 = 'light14',
  light15 = 'light15',
  dark1 = 'dark1',
  dark2 = 'dark2',
  dark3 = 'dark3',
  dark4 = 'dark4',
  dark5 = 'dark5',
  dark6 = 'dark6',
  dark7 = 'dark7',
  dark8 = 'dark8',
  dark9 = 'dark9',
  dark10 = 'dark10',
  dark11 = 'dark11',
  dark12 = 'dark12',
  dark13 = 'dark13',
  dark14 = 'dark14',
  dark15 = 'dark15',
}
